/* global FULL_APP_VERSION, __GIT_COMMIT_HASH__, __GIT_COMMIT_DATE__, __GIT_CURRENT_BRANCH__ */
import template from './about.component.html';
import { PublicVersionsService } from '~/source/common/services/public-versions.service';
import * as rx from '@proftit/rxjs';
import { Version } from '@proftit/crm.api.models.entities';
import { VersionModuleCode } from '@proftit/crm.api.models.enums';
const styles = require('./about.component.scss');

declare const FULL_APP_VERSION: string;
declare const __GIT_COMMIT_HASH__: string;
declare const __GIT_COMMIT_DATE__: string;
declare const __GIT_CURRENT_BRANCH__: string;

const versionsModuleTranslations = {
  [VersionModuleCode.Api]: 'about.API_VERSION',
};

function generateAppInfo(publicVersions): rx.Observable<Version[]> {
  return rx.obs.defer(() => {
    return rx.obs.from(publicVersions.getList());
  }) as rx.Observable<Version[]>;
}

export class AboutController {
  styles = styles;

  fullAppVersion = FULL_APP_VERSION;

  gitCommitHash = __GIT_COMMIT_HASH__;

  gitCommitDate = __GIT_COMMIT_DATE__;

  gitCurrentBranch = __GIT_CURRENT_BRANCH__;

  versionsModuleTranslations = versionsModuleTranslations;

  appInfo$: rx.Observable<Version[]>;

  /*@ngInject */
  constructor(readonly publicVersionsService: PublicVersionsService) {
    this.appInfo$ = generateAppInfo(this.publicVersionsService);
  }

  $onInit() {}

  $onChanges() {}

  $onDestroy() {}
}

export const AboutComponent = {
  template,
  controller: AboutController,
};
