import RestService from '~/source/common/services/rest';
import { Version } from '@proftit/crm.api.models.entities';
import { IPromise } from 'angular';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';

export class PublicVersionsService extends RestService {
  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'versions';
  }

  /**
   *  Return accessType name
   *
   * @returns accessType name.
   */
  get accessType() {
    return 'public';
  }

  getList(): Promise<Version[]> {
    return this.getListWithQuery<IElementRestNg<Version>>().then((data) =>
      data.plain().filter((item: any) => item.module !== 'Reports'),
    );
  }
}
