export enum ClearingCompanyCode {
  Fibonatix = 'fibonatix',
  SolidPayments = 'solidpayments',
  Zotapay = 'zotapay',
  WireCapital = 'wire_capital',
  CreditGuard = 'credit_guard',
  SafeCharge = 'safecharge',
  Orangepay = 'orangepay',
  PayWize = 'paywize',
  PayFast = 'payfast',
  Texcent = 'texcent',
  NeoBanq = 'neobanq',
  GumballPay = 'gumballpay',
  COTI = 'coti',
  Razor = 'razorpay',
  OoBit = 'oobit',
  Gateway = 'gateway',
  SecurePaymentGateway = 'secure_payment_gateway',
  QESH = 'qesh',
  PayWall = 'paywall',
  Xpay = 'xpay',
  Pradexx = 'pradexx',
  QuikiPay = 'quikipay',
}
